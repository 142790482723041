import Logger from "./Logger.js";

const logger = new Logger(console, "Weather");
logger.debug("loaded");

export class Weather {
    constructor(endpointUrl, defaultCityId, cityPath, staticLPCDNUrl) {
        logger.debug("constructing");

        if (!endpointUrl) {
            throw new TypeError("endpointUrl must be a non-empty string");
        }

        if (!cityPath) {
            throw new TypeError("cityPath must be a non-empty string");
        }

        const WEATHER_BOX_SELECTOR = ".weatherWidget";
        const WEATHER_LINK_SELECTOR = "a";
        const WEATHER_CITY_SELECTOR = ".weatherWidget__city";
        const WEATHER_ICON_SELECTOR = ".weatherWidget__weatherIcon img";
        const WEATHER_TEMPERATURE_SELECTOR = ".weatherWidget__temperature";

        const boxNode = document.querySelector(WEATHER_BOX_SELECTOR);

        if (!boxNode) {
            logger.debug("Aucun élément trouvé avec le sélecteur CSS %o", WEATHER_BOX_SELECTOR);
            return;
        }

        try {
            endpointUrl = endpointUrl + defaultCityId;

            fetch(endpointUrl)
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        return null;
                    }
                })
                .then(responseData => {
                    if (responseData !== null) {
                        const linkNode = boxNode && boxNode.querySelector(WEATHER_LINK_SELECTOR);
                        const cityNode = boxNode && boxNode.querySelector(WEATHER_CITY_SELECTOR);
                        const iconNode = boxNode && boxNode.querySelector(WEATHER_ICON_SELECTOR);
                        const temperatureNode = boxNode && boxNode.querySelector(WEATHER_TEMPERATURE_SELECTOR);
                        (linkNode) && (linkNode.href = cityPath + defaultCityId);
                        (cityNode && responseData.nameFr) && (cityNode.innerHTML = responseData.nameFr);
                        (iconNode && responseData.current.icon) && (iconNode.src = staticLPCDNUrl + "/lapresse/img/meteo/icone_une/" + responseData.current.icon + ".png");
                        (iconNode) && (iconNode.alt = "météo " + cityNode.innerHTML);
                        (temperatureNode && responseData.current.temperature !== undefined) && (temperatureNode.innerHTML = responseData.current.temperature + "&deg;C");

                        boxNode.querySelector(".weatherWidget__weatherIcon").classList.remove("display-none");
                    }
                })
                .catch(error => {
                    logger.warn("Weather => Error %o while fetching %o", error, endpointUrl);
                });
        } catch (error) {
            logger.warn("Weather => Error %o while fetching %o", error, endpointUrl);
        }
    }
}

logger.debug("adding event listener on DOMContentLoaded");
document.addEventListener("DOMContentLoaded", () => {
    logger.debug("DOMContentLoaded event triggered");
    new Weather(
        window.WEATHER_CITIES_ENDPOINT_URL,
        window.WEATHER_DEFAULT_CITY_ID,
        window.WEATHER_PATH,
        window.STATIC_LPCDN_BASE_URL,
    );
});
